<template>
	<div class="system-content">
		<div class="system-left" :style="height">
			<p class="title">系统管理</p>
			<el-menu
				:default-active="defaultActivePath"
				router>
				<el-menu-item
					v-for="(item,index) in menuList"
					:index="item.path"
					:key="index">
					<i :class="item.icon"></i>
					<span slot="title">{{item.title}}</span>
				</el-menu-item>
			</el-menu>
		</div>
		<div class="system-right" :class="className">
			<transition name="fade-transform" mode="out-in">
				<router-view :key="key"/>
			</transition>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			defaultActivePath: '',
			height: '',
			className: 'p1',
			menuList: []
		}
	},
	computed: {
        key() {
            return this.$route.path
		}
	},
	created() {
		this.init();
		// 处理路由重复点击报错问题
		this.handleRepeatRoute();
	},
	watch: {
		$route(from,to) {
			this.init();
			this.handleRepeatRoute();
		}
	},
	methods: {
		init() {
			this.height = 'min-height:' + (window.innerHeight - 180) + 'px;';
			let menuArray = [];
			if(this.perms.includes('sys:user')) {
				menuArray.push(
					{
						title: '用户管理',
						icon: 'ricoh-icon-user',
						name: 'userManagement',
						path: '/systemManagement/userManagement'
					}
				);
			}
			if(this.perms.includes('sys:role')) {
				menuArray.push(
					{
						title: '角色管理',
						icon: 'ricoh-icon-role',
						name: 'roleManagement',
						path: '/systemManagement/roleManagement'
					}
				);
			}
			if(this.perms.includes('sys:dept')) {
				menuArray.push(
					{
						title: '通讯组管理',
						icon: 'ricoh-icon-depart',
						name: 'departmentManagement',
						path: '/systemManagement/departmentManagement'
					}
				);
			}
			if(this.perms.includes('sys:agentGroup')) {
				menuArray.push(
					{
						title: '经销商分组',
						icon: 'ricoh-icon-depart',
						name: 'dealerGroup',
						path: '/systemManagement/dealerGroup'
					}
				);
			}
			if(this.perms.includes('sys:data')) {
				menuArray.push(
					{
						title: '数据类别管理',
						icon: 'ricoh-icon-data',
						name: 'dataManagement',
						path: '/systemManagement/dataManagement'
					}
				);
			}
			if(this.perms.includes('sys:deviceModel')) {
				menuArray.push(
					{
						title: '机种机型管理',
						icon: 'ricoh-icon-model',
						name: 'modelManagement',
						path: '/systemManagement/modelManagement'
					}
				);
			}
			if(this.perms.includes('sys:device')) {
				menuArray.push(
					{
						title: '机型名称管理',
						icon: 'ricoh-icon-model',
						name: 'modelName',
						path: '/systemManagement/modelName'
					}
				);

			}
			if(this.perms.includes('sys:contract')) {
				menuArray.push(
					{
						title: '签约类型管理',
						icon: 'ricoh-icon-model',
						name: 'contractManagement',
						path: '/systemManagement/contractManagement'
					}
				);
			}
			if(this.perms.includes('sys:area')) {
				menuArray.push(
					{
						title: '省市区域管理',
						icon: 'ricoh-icon-city',
						name: 'cityManagement',
						path: '/systemManagement/cityManagement'
					}
				);
			}
			if(this.perms.includes('sys:agent')) {
				menuArray.push(
					{
						title: '经销商管理',
						icon: 'ricoh-icon-dealer',
						name: 'dealerManagement',
						path: '/systemManagement/dealerManagement'
					}
				);
			}
			// if(this.perms.includes('sys:tracen')) {
			// 	menuArray.push(
			// 		{
			// 			title: '培训中心管理',
			// 			icon: 'ricoh-icon-training',
			// 			name: 'trainingManagement',
			// 			path: '/systemManagement/trainingManagement'
			// 		}
			// 	);
			// }
			if(this.perms.includes('sys:config')) {
				menuArray.push(
					{
						title: '参数设置',
						icon: 'ricoh-icon-config',
						name: 'parameterConfig',
						path: '/systemManagement/parameterConfig'
					}
				);
			}
			this.menuList = menuArray;
			// 刷新页面不自动跳转回左侧第一个菜单
			if(/systemManagement/.test(this.$route.path) && this.$route.name != 'systemManagement') {
				return
			}
			// 进入页面自动加载左侧第一个菜单
			if(menuArray.length > 0) {
				if(menuArray[0].children) {
					this.$router.push({
						path: menuArray[0].children[0].path
					})
				}else {
					this.$router.push({
						path: menuArray[0].path
					})
				}
			}
		},
		// 处理路由重复点击报错问题
		handleRepeatRoute() {
			if(this.$route.name == 'departmentManagement' || this.$route.name == 'dealerGroup') {
				this.className = 'p2';
			}else {
				this.className = 'p1';
			}
			this.defaultActivePath = this.$route.path;

		}
	}
}
</script>
<style>
	.p1{
		padding: 15px;
	}
	.p2{
		padding: 0;
	}
</style>
<style lang="scss" scoped>
.system-content{
	display: flex;
	box-sizing: border-box;
	padding-top: 20px;
	.system-left{
		width: 166px;
		background-color: #fff;
		border-radius: 10px;
		box-sizing: border-box;
		padding-top: 25px;
		padding-right: 15px;
		min-height: 520px;
		max-height: 730px;
		.title{
			height: 30px;
			font-size: 24px;
			margin-bottom: 10px;
			text-align: center;
		}
		.el-menu{
			border-right: none;
			border-radius: 10px;
			.el-menu-item{
				border-top-right-radius: 26px;
				border-bottom-right-radius: 26px;
			}
			.el-menu-item.is-active{
				background-color: #ecf5ff;
			}
		}
	}
	.system-right{
		flex: 1;
		background-color: #fff;
		border-radius: 10px;
		margin-left: 10px;
		box-sizing: border-box;
	}
}
.el-menu-item{
	padding-left: 0;
	i{
		width: 20px;
		height: 20px;
		display: inline-block;
		margin-right: 10px;
	}
	.ricoh-icon-role{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/role.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-user{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/yonghu.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-depart{
		background: url('~@/assets/images/tongxun.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-data{
		background: url('~@/assets/images/shujuleibie.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-model{
		background: url('~@/assets/images/jizhong.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-city{
		background: url('~@/assets/images/shengshiqu.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-dealer{
		width: 22px;
		height: 20px;
		background: url('~@/assets/images/jingxiaoshang.png') no-repeat;
		background-size: 19px 17px;
	}
	.ricoh-icon-training{
		background: url('~@/assets/images/peixun.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-config{
		background: url('~@/assets/images/canshu.png') no-repeat;
		background-size: 17px 17px;
	}
}
.el-menu-item.is-active{
	.ricoh-icon-role{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/role-1.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-user{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/yonghu-1.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-depart{
		background: url('~@/assets/images/tongxun-1.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-data{
		background: url('~@/assets/images/shujuleibie-1.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-model{
		background: url('~@/assets/images/jizhong-1.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-city{
		background: url('~@/assets/images/shengshiqu-1.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-dealer{
		width: 22px;
		height: 20px;
		background: url('~@/assets/images/jingxiaoshang-1.png') no-repeat;
		background-size: 19px 17px;
	}
	.ricoh-icon-training{
		background: url('~@/assets/images/peixun-1.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-config{
		background: url('~@/assets/images/canshu-1.png') no-repeat;
		background-size: 17px 17px;
	}
}
</style>
